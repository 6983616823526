@font-face {
    font-family: GraphikMedium;
    src: url(./../../Graphik/GraphikMedium.otf);
}

@font-face {
    font-family: GraphikRegular;
    src: url(./../../Graphik/GraphikRegular.otf);
}

.ContentsMDiv {
    margin-top: 221.5px;
    margin-bottom: 150px;
}

.ContentsHeadPTag {
    font-family: GraphikRegular;
    text-transform: capitalize;
    font-size: 52px;
    font-weight: 400;
    line-height: 62px;
    letter-spacing: 0.2px;
    text-align: center;
}

.ContentsContentDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ContentsWefocusPTag {
    font-family: GraphikRegular;
    line-height: 30px;
    margin: 20px 0px 54px 0px;
    max-width: 555px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.2px;
}

.ContentsRowDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 65%;
}

.ContentLefthtDiv,
.ContentRightDiv {
    background-color: white;
    width: 48%;
    color: black;
    text-align: center;
    box-shadow: 0px 4px 31px #00000026;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ContentInsideLeftDiv,
.ContentInsideRightDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ContentInsideLeftDiv {
    margin: 40px;
}

.ContentInsideRightDiv {
    margin: 40px;
}

.CardsDivPTag {
    font-family: GraphikMedium;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin-bottom: 20px;
}

.ContentCardsSubheading {
    font-family: GraphikRegular;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.2px;
    line-height: 24px;
    text-align: center;
    color: #5c5c5c;
    max-width: 268px;
}

.ContentTryForFree {
    margin-top: 40px;
    margin-bottom: 30px;
    width: 190px;
    padding: 13.5px 46px;
}

.SignUp {
    margin-top: 78px;
    margin-bottom: 57px;
    width: 157px;
    padding: 13.5px 46px;
}

.SignUp,
.ContentTryForFree {
    font-family: GraphikMedium;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: 0.2px;
    font-weight: 500;
    text-transform: capitalize;
    background-color: #4452fe;
    color: #ffffff;
    border-style: none;
}

.ContentCardImg {
    width: 100%;
}

@media only screen and (max-width: 830px) {
    .ContentsRowDiv {
        flex-direction: column;
        align-items: center;
    }
    .ContentLefthtDiv {
        width: 80%;
        margin-bottom: 33px;
    }
    .ContentRightDiv {
        width: 80%;
    }
}

@media only screen and (max-width: 600px) {
    .ContentsWefocusPTag {
        width: 70%;
        margin-left: 15%;
    }
    .ContentsRowDiv {
        flex-direction: column;
    }
    .ContentLefthtDiv {
        width: 100%;
        margin-bottom: 33px;
    }
    .ContentRightDiv {
        width: 100%;
    }
}