@font-face {
    font-family: GraphikMedium;
    src: url(./../../Graphik/GraphikMedium.otf);
}

@font-face {
    font-family: GraphikRegular;
    src: url(./../../Graphik/GraphikRegular.otf);
}

.featuresmDiv {
    margin-top: 162px;
    margin-bottom: 62px;
}

.featuresmDiv h1 {
    font-family: GraphikRegular;
    text-transform: uppercase;
    text-align: center;
    font-size: 52px;
    font-weight: 400;
    letter-spacing: 0.2px;
    margin-bottom: 27px;
}

.featuresSubHeadingDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.FeaturesPTagDiv {
    max-width: 602px;
    margin-bottom: 80px;
}

.FeaturesPTagDiv p {
    line-height: 30px;
    font-family: GraphikRegular;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.2px;
    font-weight: 400;
}

.FeatureDetailsDiv {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.FeatureScreenImgTag {
    max-width: 500px;
}

.FeatureRightDiv {
    padding: 20px;
    max-width: 300px;
}

.FeatureRowDiv {
    margin-top: 35px;
}

.FeatureRowHeadDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.FeatureIcon {
    color: #02e4c0;
    margin-right: 12px;
}

.FeatureHeadSpan {
    font-family: GraphikMedium;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.1px;
}

.FeaturePTag {
    font-family: GraphikRegular;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

@media only screen and (max-width: 870px) {
    .FeatureDetailsDiv {
        flex-direction: column;
    }
    .FeatureScreenImgTag {
        margin-bottom: 50px;
    }
    .FeatureRowHeadDiv {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 600px) {
    .FeaturesPTagDiv p {
        width: 80%;
        margin-left: 10%;
    }
}

@media only screen and (max-width: 500px) {
    .FeatureScreenImgTag {
        width: 90%;
    }
}