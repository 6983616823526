@font-face {
    font-family: GraphikMedium;
    src: url(./../../Graphik/GraphikMedium.otf);
}

@font-face {
    font-family: GraphikRegular;
    src: url(./../../Graphik/GraphikRegular.otf);
}

.GallerymDiv {
    margin-bottom: 220px;
}

.GalleryPTitle {
    font-family: GraphikRegular;
    font-weight: 400;
    font-size: 52px;
    line-height: 62px;
    letter-spacing: 0.2px;
    text-align: center;
    margin-bottom: 20px;
}

.GalleryContentDiv,
.GalleryContentNDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.GalleryContentNDiv {
    width: 70%;
}

.GalleryWeFocus {
    font-family: GraphikRegular;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.2px;
    max-width: 591px;
    text-align: center;
    margin-bottom: 44px;
}

.CardsRow1,
.CardsRow2 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.CardsRow1 {
    padding: 0px 44px;
}

.CardsImg1 {
    max-width: 225px;
}

.CardsImg2 {
    max-width: 395px;
    height: 285px;
}

.GallerySeeMore {
    background-color: transparent;
    color: #ffffff;
    padding: 10px 30px;
    border-style: solid;
    border-width: 1px;
    border-color: #ffffff;
    margin-top: 44px;
    box-shadow: 0px 4px 31px #00000026;
    font-family: GraphikMedium;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 25px;
}

@media only screen and (max-width: 1000px) {
    .CardsRow1 {
        padding: 0px;
    }
    .CardsRow2 {
        display: none;
    }
}

@media only screen and (max-width: 880px) {
    .CardsRow1 {
        flex-direction: column;
    }
    .CardsImg1 {
        min-width: 300px;
        width: 100%;
    }
}