@font-face {
    font-family: GraphikMedium;
    src: url(./../../Graphik/GraphikMedium.otf);
}

@font-face {
    font-family: GraphikRegular;
    src: url(./../../Graphik/GraphikRegular.otf);
}

.TestimonialsMainDiv {
    margin-bottom: 230px;
}

.TestimonialsRowCMDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 60px;
}

.TestimonialsCDivP {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.TestimonialsContentDivRows {
    max-width: 500px;
    padding: 40px;
    border-style: solid;
    border-width: 1px;
    border-color: #dedede;
    border-radius: 10px;
}

#TestimonialsMargin {
    margin-bottom: 20px;
}

.TestimonialsPRowTag {
    font-family: GraphikRegular;
    font-size: 16px;
    font-weight: 400px;
    line-height: 24px;
    letter-spacing: 0.2px;
    width: 100%;
}

.TestimonialsUserDiv {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}

.UserIconDiv {
    margin-right: 23px;
}

.userName,
.UserProf {
    font-family: GraphikMedium;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.userName {
    letter-spacing: 0.2px;
}

.UserProf {
    letter-spacing: 0.1px;
}

#TestimonialsmarginTopNegative {
    margin-top: -23px;
}

.TestimonialsDivParent {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.TestimonialsDiv {
    width: 70%;
}

@media only screen and (max-width: 850px) {
    .TestimonialsCDivP {
        width: 60%;
        margin-right: 5%;
    }
}

@media only screen and (max-width: 800px) {
    .TestimonialsRowCMDiv {
        flex-direction: column;
    }
    .TestimonialsContentDivRows {
        margin-bottom: 23px;
    }
    #TestimonialsMargin {
        margin-top: 23px;
    }
    .TestimonialsCDivP {
        margin-right: 0%;
        width: 100%;
    }
}