.NavMoboMDiv {
    padding-top: 20px;
    display: none;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.hamBurger {
    width: 44px;
    height: 26.5px;
}

.ham1,
.ham2,
.ham3 {
    float: right;
    height: 3px;
    background-color: white;
}

.ham1 {
    width: 100%;
    margin-bottom: 5px;
}

.ham2 {
    width: 75%;
    margin-bottom: 5px;
}

.ham3 {
    width: 50%;
}

@media only screen and (max-width: 800px) {
    .NavMoboMDiv {
        display: flex;
    }
}

@media only screen and (max-width: 500px) {
    .NavLoginbtn {
        padding: 15px 20px;
        width: 110px;
    }
}

@media only screen and (max-width: 380px) {
    .NavLoginbtn {
        padding: 7px 10px;
        width: 80px;
    }
}