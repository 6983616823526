@font-face {
    font-family: GraphikRegular;
    src: url(./../../Graphik/GraphikRegular.otf);
}

.PartnersMainDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 200px;
}

.PartnersmDiv {
    font-family: GraphikRegular;
    text-align: center;
    font-size: 52px;
    font-weight: 400;
    line-height: 62px;
    letter-spacing: 0.2px;
}

.PartnersContentDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.FeaturesWeFocus {
    margin-top: 10px;
    font-family: GraphikRegular;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.2px;
    max-width: 591px;
    text-align: center;
    margin-bottom: 60px;
}

.FeaturesImgLogoDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 80%;
    margin-bottom: 50px;
}

.FeaturesImgLogoDivRespo {
    display: none;
}

@media only screen and (max-width: 770px) {
    .FeaturesImgLogoDiv {
        display: none;
    }
    .FeaturesImgLogoDivRespo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;
        margin-bottom: 50px;
    }
    .FeaturesImgLogoREspo0,
    .FeaturesImgLogoREspo1 {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }
    .FeaturesImgLogoREspo0 {
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 600px) {
    .FeaturesWeFocus {
        width: 70%;
    }
}