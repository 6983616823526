@font-face {
    font-family: GraphikMedium;
    src: url(./../../Graphik/GraphikMedium.otf);
}

.HeaderNav {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.NavmContentDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
}

.ListDiv {
    text-align: center;
    width: 70%;
}

.ListDiv ul {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.ListDiv ul li {
    font-family: GraphikMedium;
    display: inline-block;
    list-style-type: none;
}

.NavLoginbtn {
    border-style: solid;
    border-width: 1px;
    border-color: white;
    background-color: transparent;
    color: white;
    width: 139px;
    font-size: 17px;
    padding: 15px 36px;
    cursor: pointer;
}

.NavLoginbtn p {
    font-family: GraphikMedium;
}

@media only screen and (max-width: 800px) {
    .HeaderNav {
        display: none;
    }
}