@font-face {
    font-family: GraphikMedium;
    src: url(./../../Graphik/GraphikMedium.otf);
}

.FooterMDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 107px;
}

.FooterDiv {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.UlDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 70%;
}

.UlMDiv ul li {
    list-style-type: none;
    font-family: GraphikMedium;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    margin-bottom: 19px;
}

.listTitle {
    margin-bottom: 40px !important;
    font-family: GraphikMedium;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.1px;
}

.PIconDiv {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.2px;
    font-weight: 500;
}

.SocialIconsDiv {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 20px 0px;
}

.ContactUsDiv {
    width: 35%;
}

@media only screen and (max-width: 1000px) {
    .FooterDiv {
        width: 80%;
    }
}

@media only screen and (max-width: 1000px) {
    .FooterDiv {
        width: 100%;
    }
}

@media only screen and (max-width: 650px) {
    .FooterDiv {
        width: 65%;
    }
    .UlDiv,
    .FooterDiv {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .UlDiv {
        margin-bottom: 75px;
    }
    .ContactUsDiv {
        width: 100%;
    }
    .listTitle {
        margin-top: 75px;
    }
}