@font-face {
    font-family: GraphikMedium;
    src: url(./../../Graphik/GraphikMedium.otf);
}

@font-face {
    font-family: GraphikRegular;
    src: url(./../../Graphik/GraphikRegular.otf);
}

.HeaderMDiv {
    margin-top: 95px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.HeaderH1Div {
    width: 60%;
    max-width: 730px;
    text-align: center;
}

.HeaderH1Div h1 {
    font-family: GraphikMedium;
    font-size: 80px;
    line-height: 88px;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin-bottom: 30px;
}

.HeaderPDiv {
    margin-top: 37px;
    max-width: 500px;
    text-align: center;
    margin-bottom: 85px;
}

.HeaderPDiv p {
    line-height: 30px;
    font-family: GraphikRegular;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.2px;
    font-weight: 400;
}

.BtnDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 500px;
}

.TryForFree,
.LearnMore {
    font-family: GraphikMedium;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    padding: 10px 30px;
    width: 150px;
    height: 40px;
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 0.2px;
}

.TryForFree {
    background-color: #4452fe;
}

.LearnMore {
    border-style: solid;
    border-color: #ffffff;
    border-width: 1px;
}

@media only screen and (max-width: 550px) {
    .HeaderH1Div h1 {
        font-family: GraphikRegular;
        font-size: 52px;
        line-height: 62px;
    }
    .BtnDiv {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .TryForFree {
        margin-bottom: 21px;
    }
    .HeaderPDiv p {
        width: 80%;
        margin-left: 10%;
    }
}