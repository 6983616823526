@font-face {
    font-family: GraphikRegular;
    src: url(./../../Graphik/GraphikRegular.otf);
}

.CtamDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 287px;
}

.CtaContentDiv {
    width: 83%;
    border-radius: 33px;
    background-color: #343434;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ctaPTag {
    font-family: GraphikRegular;
    font-size: 52px;
    font-weight: 400;
    line-height: 62px;
    letter-spacing: 0.2px;
    margin-bottom: 37px;
    text-align: right;
}

.CtaLeftDiv {
    width: 45%;
    padding-left: 94px;
    margin: 61.5px 0px;
}

.CtaRightDiv {
    width: 43%;
    height: 100%;
    display: flex;
    align-items: end;
}

.ctaImgTag {
    width: 100%;
    min-height: 300px;
    border-radius: 0px 0px 33px 0px;
}

@media only screen and (max-width: 1000px) {
    .CtaContentDiv {
        width: 90%;
    }
}

@media only screen and (max-width: 900px) {
    .CtaContentDiv {
        width: 100%;
        border-radius: 0px;
        flex-direction: row;
    }
    .ctaImgTag {
        border-radius: 0px;
    }
}

@media only screen and (max-width: 900px) {
    .CtaLeftDiv {
        padding-left: 50px;
    }
}

@media only screen and (max-width: 750px) {
    .CtaLeftDiv {
        padding-left: 30px;
    }
}

@media only screen and (max-width: 700px) {
    .CtaContentDiv {
        flex-direction: column;
    }
    .CtaLeftDiv {
        width: 100%;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .ctaPTag {
        width: 80%;
        text-align: center;
    }
    .CtaRightDiv {
        width: 90%;
    }
}